export default (str: string): {isValid: boolean; value: Record<string, any>} => {
  try {
    const value = JSON.parse(str)
    return {
      isValid: true,
      value,
    }
  } catch {
    return {
      isValid: false,
      value: {},
    }
  }
}
