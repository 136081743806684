import { Button, Checkbox, Flex, Form, Input, Radio, Typography } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IoSend } from 'react-icons/io5'

import message from '../../../services/message'
import changeCaseOwner from '../../../services/saleston/changeCaseOwner'
import createCaseFeedItem from '../../../services/saleston/createCaseFeedItem'
import postCaseTranscript from '../../../services/saleston/postCaseTranscript'
import { FeedTypeEnum, SFCasePostFeedItemData } from '../../../services/saleston/types'
import { TabContext } from '../../templates/Client'

const { Title } = Typography

const CaseFeedForm = () => {
  const { drawer, account, phoneNumber, agentEmail } = useContext(TabContext)
  const [form] = Form.useForm()
  const [createFeedLoading, setCreateFeedLoading] = useState<boolean>(false)

  const feedType = Form.useWatch('type', form)

  useEffect(() => {
    form.resetFields()
  }, [form, drawer?.caseData.id])

  const { profileName, userId } = JSON.parse(localStorage.getItem('salesforce-credentials') || '{}')

  const onFinish = useCallback(
    async (feedData: SFCasePostFeedItemData) => {
      const feedItem = {
        ...feedData,
        caseId: drawer?.caseData.id!,
      }

      try {
        setCreateFeedLoading(true)

        await createCaseFeedItem(feedItem)

        if (feedItem.changeOwner === true) {
          await changeCaseOwner({
            caseId: drawer?.caseData.id!,
            ownerId: userId,
          })

          window.dispatchEvent(new CustomEvent('case:ownerChanged', { detail: { accountId: account?.Id } }))
        }

        setCreateFeedLoading(false)
        form.resetFields()

        if ((phoneNumber || agentEmail) && (feedItem.type === FeedTypeEnum.RECALL)) {
          postCaseTranscript({
            caseId: drawer?.caseData.id!,
            email: agentEmail,
            phone: phoneNumber,
          })
        }

        window.dispatchEvent(new CustomEvent('case:feedCreated', { detail: { accountId: account?.Id } }))

        message({
          type: 'success',
          content: feedItem.changeOwner === true
            ? 'Feed adicionado e caso transferido para você com sucesso!'
            : 'Feed adicionado com sucesso!',
        })
      } catch {
        setCreateFeedLoading(false)
        message({
          type: 'error',
          content: 'Ocorreu algo errado! Tente Novamente',
        })
      }
    },
    [drawer?.caseData.id, account, form, phoneNumber, agentEmail, userId],
  )

  const isOwnerOptionVisible = feedType === FeedTypeEnum.RECALL && profileName !== 'Ton_Terceiro'

  return (
    <>
      <Title level={5} style={{ marginBottom: '15px' }}>Feed</Title>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        initialValues={{
          type: FeedTypeEnum.TEXTPOST,
        }}
      >
        <Form.Item
          name="type"
          rules={[{
            required: true,
            message: 'É necessário escolher o tipo',
          }]}
          style={{ marginBottom: '10px' }}
        >
          <Radio.Group>
            <Radio.Button value={FeedTypeEnum.TEXTPOST}>Publicação</Radio.Button>
            <Radio.Button value={FeedTypeEnum.RECALL}>Rechamado</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name='body' style={{ marginBottom: '10px' }}>
          <Flex style={{ alignItems: 'center' }}>
            <Input.TextArea
              placeholder='Descrição'
              style={{
                marginRight: '15px',
                height: '80px',
              }}
            />
            <Button
              loading={createFeedLoading}
              style={{
                alignItems: 'center',
              }}
              shape='circle'
              size='large'
              type='primary'
              htmlType='submit'
              icon={<IoSend
                style={{
                  fontSize: '1.1rem',
                  marginTop: '.2rem',
                }}
              />}
            />
          </Flex>
        </Form.Item>

        <Form.Item name='changeOwner' valuePropName='checked' hidden={!isOwnerOptionVisible}>
          <Checkbox checked={false}>Atribuir a propriedade do caso para mim</Checkbox>
        </Form.Item>
      </Form>
    </>
  )
}

export default CaseFeedForm
