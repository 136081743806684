import { z } from 'zod'

import safeParseJson from '../../utils/safeParseJson'
import useEventListener from '../useListener'

import { Tab, TabEvent } from './types'

export const eventSchema = z.object({
  action: z.string(),
  agentEmail: z.string().email(),
  communicationChannel: z.string().optional().default('unknown'),
  socketMessage: z.object({
    phoneNumber: z.string(),
    task_id: z.string(),
    document: z.string(),
    urn: z.string().optional(),
    transference: z.object({
      fromDepartment: z.string().optional(),
      to: z.string().optional(),
      from: z.string().optional(),
    }).optional(),
  }),
  worker: z.object({
    profile: z.string(),
    teams: z.string().array(),
    contactChannels: z.string().array(),
  }).optional(),
})

const buildURN = (data: TabEvent) => {
  const { communicationChannel: channel, socketMessage: { urn, phoneNumber } } = data
  if (urn) {
    return urn
  }

  if (channel && phoneNumber) {
    const newUrn = `${channel}:${phoneNumber}`
    console.info('Built URN: ', newUrn)
    return newUrn
  }

  const newUrn = `mocked:${window.crypto.randomUUID()}`
  console.info('Random URN: ', newUrn)
  return newUrn
}

export default (action: string, handler: (data: Tab) => void) => {
  useEventListener(
    'message',
    (e) => {
      const { isValid, value } = safeParseJson((e as MessageEvent<string>).data)
      console.info('tab:open', value)

      if (!isValid) return
      if (value.action !== action) return

      let event: TabEvent
      try {
        event = eventSchema.parse(value)
      } catch (e) {
        console.error(e)
        event = value as TabEvent
      }

      handler({
        ...event.socketMessage,
        channel: event.communicationChannel,
        urn: buildURN(event),
        placeholder: event.socketMessage.document || event.socketMessage.phoneNumber || 'NOVA ABA',
      })
    })
}
