import { Alert, AlertProps, Button, Checkbox, Col, Form, Input, Row, Select } from 'antd'
import React, { ReactElement, useCallback, useContext, useState } from 'react'
import { useLocalStorage } from 'react-use'

import createCaseReturningFullCaseObject from '../../../services/saleston/createCaseReturningFullCaseObject'
import postCaseTranscript from '../../../services/saleston/postCaseTranscript'
import { SFCase } from '../../../services/saleston/types'
import arrayToOption from '../../../utils/arrayToOption'
import getEnv from '../../../utils/getEnv'
import removeExtraSpaces from '../../../utils/removeExtraSpaces'
import removeFromLocalStorage from '../../../utils/removeFromLocalStorage'
import RecallAlert from '../../molecules/Alerts/Recall'
import CollapsableCard from '../../molecules/CollapseCard'
import DrawerTrigger from '../../molecules/DrawerTrigger'
import DynamicFields from '../../molecules/Form/DynamicFields'
import GuidedService from '../../molecules/GuidedService'
import MacroModal from '../../molecules/MacroModal/ index'
import { TabContext } from '../../templates/Client'

const HIDE_UNDUE_TRANSFER = ['n1 aec', 'n1 teleperformance', 'n1 concentrix']

const CreateCaseCard = () => {
  const { urn, account, task_id, phoneNumber, agentEmail, worker, transference } = useContext(TabContext)

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [alert, setAlert] = useState<{ message: any; type: AlertProps['type']; action?: ReactElement } | null>(null)
  const [form] = Form.useForm()
  const [storedCase, storeCase] = useLocalStorage(`pluton-${urn}-create-case-form`, form.getFieldsValue())
  const [createCaseLoading, setCreateCaseLoading] = useState<boolean>(false)
  const [topic, storeTopic, resetTopic] = useLocalStorage(`pluton-${urn}-create-case-topic`, '')
  const [category, storeCategory, resetCategory] = useLocalStorage(`pluton-${urn}-create-case-category`, '')
  const [subject, storeSubject, resetSubject] = useLocalStorage(`pluton-${urn}-create-case-subject`, '')

  const checkErrors = useCallback(() => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length > 0)

    setAlert(hasErrors
      ? {
        type: 'error',
        message: 'O formulário possui erros. Por favor, verifique os campos preenchidos acima.',
      }
      : null)
  }, [form])

  const insertTitleWithDynamicFields = useCallback((topicValue, categoryValue, subjectValue) => {
    const hasValues = topicValue && categoryValue && subjectValue
    if (!hasValues) return

    const currentValue = form.getFieldValue('Subject') || ''
    const value = removeExtraSpaces(`${topicValue} : ${categoryValue} : ${subjectValue}`.replace(/\((.*?)\)/, ''))

    const isWritten = currentValue.match(/.+ : .+ : .+/)
    const extraInfo = isWritten
      ? removeExtraSpaces((currentValue.split('|')[1] || '').replace('|', ''))
      : currentValue

    form.setFieldValue('Subject', extraInfo ? `${value} | ${extraInfo}` : value)
  }, [form])

  const updateLocalStorage = useCallback(() => {
    storeCase(form.getFieldsValue())
  }, [form, storeCase])

  const isOnTeam = useCallback((allowed: string[]) => worker?.teams.some((team) => allowed.some((group) => team === group)), [worker?.teams])

  const isPossibleUndueTransfer = !isOnTeam(HIDE_UNDUE_TRANSFER) && transference?.fromDepartment

  const onFinish = useCallback(
    async (caseData: SFCase) => {
      try {
        setCreateCaseLoading(true)

        const data = await createCaseReturningFullCaseObject({
          ...caseData,
          accountId: account?.Id,
          Twilio_Task_Id__c: task_id,
          Transfer_ncia_Indevida__c: isPossibleUndueTransfer && caseData.Transfer_ncia_Indevida__c === 'Sim'
            ? transference?.fromDepartment?.replace(/\[.*\]/, '').trim()
            : '',
        })

        setCreateCaseLoading(false)

        const createdCase = {
          id: data.Id,
          number: data.CaseNumber,
          createdDate: data.CreatedDate,
          status: data.Status,
          subject: data.Subject,
          accountName: data.Account.Name,
          ownerName: data.Owner.Name,
        }

        setAlert({
          message: (
            <>
              Caso <DrawerTrigger caseData={createdCase} /> criado com sucesso!
            </>
          ),
          type: 'success',
          action: (
            <Button
              size='small'
              target="_blank"
              href={`${getEnv('REACT_APP_SALESFORCE_URL')}/lightning/r/Case/${data.Id}/view`}
            >
              Ver no Salesforce
            </Button>
          ),
        })

        if (phoneNumber || agentEmail) {
          postCaseTranscript({
            caseId: data.Id,
            email: agentEmail,
            phone: phoneNumber,
          })
        }

        window.dispatchEvent(new CustomEvent('case:created', { detail: { accountId: account?.Id } }))
      } catch {
        setCreateCaseLoading(false)
        setAlert({
          message: 'Ocorreu algo errado! Tente Novamente',
          type: 'error',
        })
      }
    },
    [account?.Id, task_id, isPossibleUndueTransfer, transference?.fromDepartment, phoneNumber, agentEmail],
  )

  return (
    <CollapsableCard iconName='createCase.svg' title='Registro de Caso' loading={false} onChange={() => { }} noPadding>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        onSubmitCapture={() => {
          form.validateFields()
            .catch(() => {
              checkErrors()
            })
        }}
        onValuesChange={updateLocalStorage}
        initialValues={{
          ...storedCase,
          status: 'Resolvido',
        }}
      >
        <Form.Item
          label='Título do caso'
          name='Subject'
          rules={[{
            required: true,
            message: 'Por favor informe o título!',
          }]}
        >
          <Input placeholder='Informe o título' />
        </Form.Item>

        <Form.Item label='Descrição do caso' name='description'>
          <Input.TextArea placeholder='Escreva a descrição aqui' />
        </Form.Item>

        <Form.Item name='Demanda_Nao_Realizada__c' valuePropName='checked'>
          <Checkbox checked={false}>Demanda não realizada</Checkbox>
        </Form.Item>

        <Row justify='space-around' gutter={20}>
          {
            isPossibleUndueTransfer && (
              <Col flex='auto'>
                <Form.Item
                  label='Transferência Indevida'
                  name='Transfer_ncia_Indevida__c'
                  rules={[{
                    required: true,
                    message: 'Por favor informe se esse caso foi uma transferência indevida!',
                  }]}
                >
                  <Select options={arrayToOption(['', 'Sim', 'Não'])} />
                </Form.Item>
              </Col>
            )
          }

          <Col flex='auto'>
            <Form.Item label='Status' name='status'>
              <Select options={arrayToOption(['Novo', 'Aberto', 'Pendente', 'Resolvido'])} />
            </Form.Item>
          </Col>

          <Col flex='auto'>
            <Form.Item label='Momento UAU' name='Momento_UAU__c'>
              <Select
                options={arrayToOption(['Vou fazer um momento UAU', 'Fiz um momento UAU', 'Resposta UAU', 'Brinde'])} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <CollapsableCard title='Definindo atendimento' autoOpen loading={false} onChange={() => { }} noMargin>
            <DynamicFields
              form={form}
              onValuesChange={(...fields) => {
                updateLocalStorage()
                insertTitleWithDynamicFields(...fields)
              }}
              topic={{
                value: topic,
                store: storeTopic,
                reset: resetTopic,
              }}
              category={{
                value: category,
                store: storeCategory,
                reset: resetCategory,
              }}
              subject={{
                value: subject,
                store: storeSubject,
                reset: resetSubject,
              }} />
          </CollapsableCard>
        </Form.Item>

        {(topic && category && subject) && (
          <Form.Item>
            <GuidedService selectors={[topic, category, subject]} />
          </Form.Item>
        )}

        {alert && (
          <Form.Item>
            <Alert
              message={alert.message}
              type={alert.type}
              action={alert.action}
              showIcon
              closable
              onClose={() => setAlert(null)} />
          </Form.Item>
        )}

        {(topic && category && subject) && (
          <Form.Item>
            <RecallAlert selectors={[topic, category, subject]} />
          </Form.Item>
        )}

        <Form.Item>
          <Row justify='space-evenly'>
            <Col>
              <Button size='large' type='primary' htmlType='submit' loading={createCaseLoading}>
                Abrir novo caso
              </Button>
            </Col>
            <Col>
              <Button
                size='large'
                type='default'
                onClick={() => {
                  removeFromLocalStorage(`pluton-${urn}-create-case`)
                  form.resetFields()
                }}
              >
                Limpar campos
              </Button>
            </Col>
            <Col>
              <Button
                size='large'
                type='default'
                onClick={() => {
                  setIsModalVisible(true)
                }}
              >
                Preencher Caso
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <MacroModal visible={isModalVisible} setVisible={setIsModalVisible} form={form} />
    </CollapsableCard>
  )
}

export default CreateCaseCard
