import { Row } from 'antd'
import { randomUUID } from 'crypto'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import { Case, CreateTab, Tab, TabMessage } from '../../hooks/tabs/types'
import { DEFAULT_TAB_KEY } from '../../hooks/tabs/useTabs'
import { SFAccount } from '../../services/saleston/types'
import removeFromLocalStorage from '../../utils/removeFromLocalStorage'
import CreateCaseCard from '../organisms/CreateCaseCard'
import ClientDrawer from '../organisms/Drawer'
import IncidentsCard from '../organisms/Incidents'
import SearchUserCard from '../organisms/SearchUserCard'
import UserRecordsCard from '../organisms/UserRecordsCard'
import VSCard from '../organisms/VSCard'

interface DefaultProps {
  data: Tab
  createTab: CreateTab
}

export const TabContext = React.createContext<Tab>({
  agentEmail: '',
  channel: '',
  urn: '',
  placeholder: '',
  drawer: {
    caseData: {
      id: '',
      number: '',
      createdDate: '',
      status: '',
      subject: '',
      accountName: '',
      ownerName: '',
    },
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    open: (caseData: Case) => {},
    close: () => {},
  },
})

export default function Client ({
  data,
  createTab,
}: DefaultProps) {
  const { account, urn } = data
  const navigate = useNavigate()
  const [selectedAccount, setSelectedAccount, removeAccount] = useLocalStorage<SFAccount>(
    `pluton-${urn}-account`,
    account,
  )
  const [drawer, setDrawer] = useState<Tab['drawer']>({
    caseData: {
      id: '',
      number: '',
      createdDate: '',
      status: '',
      subject: '',
      accountName: '',
      ownerName: '',
    },
    isOpen: false,
  })

  useEffect(() => {
    const onNotAuthorized = (event: MessageEvent) => {
      if (event.data === 'NotAuthorized') {
        removeFromLocalStorage('amplify')
        removeFromLocalStorage('Cognito')
        window.location.replace('/')
      }
    }

    window.addEventListener('message', onNotAuthorized, false)

    return () => {
      window.removeEventListener('message', onNotAuthorized, false)
    }
  }, [navigate])

  const selectAccount = useCallback((newAccount: SFAccount) => {
    if (!newAccount) return

    removeAccount()

    if (urn === DEFAULT_TAB_KEY) {
      createTab({
        account: newAccount,
        document: newAccount.CNPJ__c,
        urn: `mocked:${window.crypto.randomUUID()}`,
      } as Tab)
      return
    }

    setSelectedAccount(newAccount)

    window.dispatchEvent(new Event('account:selected'))
  }, [removeAccount, createTab, setSelectedAccount, urn])

  return (
    <TabContext.Provider value={{
      ...data,
      account: selectedAccount,
      drawer: {
        ...drawer,
        open: (caseData: Case) => setDrawer({
          caseData,
          isOpen: true,
        }),
        close: () => setDrawer({
          caseData: {
            id: '',
            number: '',
            createdDate: '',
            status: '',
            subject: '',
            accountName: '',
            ownerName: '',
          },
          isOpen: false,
        }),
      } as Tab['drawer'],
    }}>
      {selectedAccount
        ? (
          <>
            <IncidentsCard />
            <SearchUserCard
              selectedAccount={selectedAccount}
              setSelectedAccount={selectAccount}
            />
            <VSCard />
            <CreateCaseCard />
            <UserRecordsCard />
            <ClientDrawer />
          </>
        )
        : (
          <>
            {(urn === DEFAULT_TAB_KEY) && (
              <div style={{ margin: '2% 0' }}>
                <Row justify='center'>
                  <img src='./images/camuzzi.svg' alt='camuzzi' style={{
                    width: '8rem',
                    margin: '2% 0',
                  }} />
                </Row>
                <Row justify='center'>
                  <p style={{
                    margin: 0,
                    fontSize: 17,
                  }}
                  >
                    Opa! Utilize essa aba da ⭐apenas em caso de <b>falhas</b>.
                  </p>
                </Row>
                <Row justify='center'>
                  <p style={{
                    margin: 0,
                    fontSize: 17,
                  }}
                  >
                    Caso contrário o time de planejamento vai saber e ficar triste comigo!
                  </p>
                </Row>
              </div>
            )}

            <SearchUserCard
              selectedAccount={selectedAccount}
              setSelectedAccount={selectAccount}
            />
          </>
        )}
    </TabContext.Provider>
  )
}
