import { useCallback, useState } from 'react'
import { useLocalStorage } from 'react-use'

import getAuthenticationPayload from '../../utils/getAuthenticationPayload'
import removeFromLocalStorage from '../../utils/removeFromLocalStorage'

import { CreateTab, Tab, TabMessage } from './types'

export const DEFAULT_TAB_KEY = '_DEFAULT'
export const DEFAULT_TAB: Tab = {
  agentEmail: DEFAULT_TAB_KEY,
  channel: DEFAULT_TAB_KEY,
  urn: DEFAULT_TAB_KEY,
  placeholder: '⭐',
}

const useTabs = () => {
  const [openTab, setOpenTab] = useState<string>(DEFAULT_TAB_KEY)
  const [tabs, setTabs] = useLocalStorage<Tab[]>('pluton-tabs', [])

  const createTab = useCallback<CreateTab>(
    async (data) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { email } = await getAuthenticationPayload()

      const tab: Tab = {
        ...data,
        agentEmail: data.agentEmail || email,
        placeholder: data.placeholder || data.document || data.phoneNumber || 'NOVA ABA',
      }

      const tabExists = tabs?.some(({ urn }) => urn === tab.urn)

      if (!tabExists) {
        setTabs([...(tabs || []), tab])
      }

      setOpenTab(tab.urn)
    },
    [tabs, setTabs],
  )

  const removeTab = useCallback(
    (key, action) => {
      if (action !== 'remove') return
      if (key === DEFAULT_TAB) return

      removeFromLocalStorage(key)
      setTabs(tabs?.filter(({ urn }) => urn !== key))
    },
    [tabs, setTabs],
  )

  return {
    tabs: tabs || [],
    createTab,
    removeTab,
    openTab,
    setOpenTab,
  }
}

export default useTabs
